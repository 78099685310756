import React from 'react';

export default function Typography() {
  return (
      <>
    <div className='sec-bg'>
    <h1>Typrography</h1>
      
    </div>

<h1>test</h1>

    </>
  )
}
